/**
 * @summary !IMPORTANT! This is public facing, no API keys should go here!
 */
import isBrowser from 'utils/isBrowser';
import { BACKEND_ENDPOINTS } from 'consts';
import { LogError } from './utils/logging';

const PROD_TRIADMS_API_VERSION = 'v8.0';
const STG_TRIADMS_API_BETA_VERSION = 'v8.0';
const UAT_TRIADMS_API_BETA_VERSION = 'v8.0';
const DEV1_TRIADMS_API_BETA_VERSION = 'v8.0';
export const LOCAL_TRIADMS_API_BETA_VERSION = 'v8.0';

// See configs/webpack/themes.config.js to know how this got here
function getThemeMap() {
  if (isBrowser()) {
    return {};
  }

  try {
    return process.env.THEMES.split('|').reduce(
      (themeMap, theme) => ({
        ...themeMap,
        [theme]: process.env[theme],
      }),
      {}
    );
  } catch (error) {
    LogError('THEME ERROR: Failed to generate Theme map!', process.env);
    return {};
  }
}

// TODO: [T1-9673] update development widget to support multiple backend dev envs
const COMMON_ENV_VARS = {
  configEnv: process.env.NEXT_PUBLIC_CONFIG_ENV,
  buildId: process.env.NEXT_PUBLIC_BUILD_ID || 'local',
  revisionId: process.env.NEXT_PUBLIC_REVISION_ID || 'local',
  isRedisDisabled: !!process.env.REDIS_DISABLE,
  availableThemes: getThemeMap(),
};

// Production Configs
const prodConfig = {
  ...COMMON_ENV_VARS,
  cloudBucket: 'https://storage.googleapis.com/prd-wordpress',
  WPHost: 'https://cms-prd.triadplatform.com',
  WPDomain: 'cms-prd.triadplatform.com',
  triadBackendDomain: `${BACKEND_ENDPOINTS.PROD}${PROD_TRIADMS_API_VERSION}`,
  abTestingClientKey: 'sdk-uiFDftKkypHKsmc',
  isDebugEnabled: false,
};

// Staging Configs
const stgConfig = {
  ...COMMON_ENV_VARS,
  cloudBucket: 'https://storage.googleapis.com/stg-wordpress',
  WPHost: 'https://triadmsstage.wpengine.com',
  WPDomain: 'triadmsstage.wpengine.com',
  triadBackendDomain: `${BACKEND_ENDPOINTS.MUDD1}${STG_TRIADMS_API_BETA_VERSION}`,
  abTestingClientKey: 'sdk-zUuz7peuaTdO5pyW',
  isDebugEnabled: true,
};

// Uat Configs
const uatConfig = {
  ...COMMON_ENV_VARS,
  cloudBucket: 'https://storage.googleapis.com/uat-wordpress',
  WPHost: 'https://triadmsuat.wpengine.com',
  WPDomain: 'triadmsuat.wpengine.com',
  triadBackendDomain: `${BACKEND_ENDPOINTS.MUDD3}${UAT_TRIADMS_API_BETA_VERSION}`,
  abTestingClientKey: 'sdk-bCY5hylWHcnFyEHF',
  isDebugEnabled: true,
};

// Dev 1 Configs
const dev1Config = {
  ...uatConfig,
  triadBackendDomain: `${BACKEND_ENDPOINTS.MUDD5}${DEV1_TRIADMS_API_BETA_VERSION}`,
};

// Development Configs
const devConfig = {
  ...COMMON_ENV_VARS,
  developmentSiteUrl: process.env.DEV_SITE_URL || '',
  cloudBucket: 'https://storage.googleapis.com/dev-wordpress',
  triadBackendDomain:
    process.env.DEV_BACKEND_DOMAIN ||
    `${BACKEND_ENDPOINTS.MUDD4}${LOCAL_TRIADMS_API_BETA_VERSION}`,
  WPHost: process.env.WP_HOST || 'triadms-wordpress',
  WPDomain: process.env.WP_DOMAIN || 'triadms-wordpress.local',
  abTestingClientKey: 'sdk-0qfeoMIxJTqidto3',
  isDebugEnabled: true,
};

const CONFIG_MAP = {
  production: prodConfig,
  staging: stgConfig,
  uat: uatConfig,
  development: devConfig,
  dev1: dev1Config,
  undefined: {},
};

export default CONFIG_MAP[process.env.NEXT_PUBLIC_CONFIG_ENV];
